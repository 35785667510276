import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { window } from 'browser-monads'


export default class SupportPage extends React.Component{
    handleSubmit = e => {
    window.Intercom('showNewMessage')
    e.preventDefault();
    }
    render() {
        return(
            <Layout>
                <SEO title="Optinly Support - Sales Enquiry and Live Chat Hub"
                    description="Contact our Optinly technical support to resolve your query. We have a team of highly-skilled engineers and eCommerce specialists to help you out." 
                    keywords={[`Optinly support`, `ecommerce automation support`, `woocommerce marketing automation`, `shopify marketing automation`, `Optinly contact form`]} />
                <div className="container">
                    <div className="contact-intro text-center">
                        <h3>Support</h3>
                        <p>Please leave us a message and we will get back to you shortly</p>
                    </div>
                    <div className="contact-form d-flex justify-content-center">
                        <a className="btn btn-cta mx-auto text-center" onClick={this.handleSubmit}>Send us a message</a>
                    </div>
                </div>
            </Layout>
        )
    }
}
